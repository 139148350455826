<template>
  <GroupReservations
    v-if="group"
    :current-sale-status="group.sale_status"
    :current-group-id="group.id"
    @update="regenerateComponentsUuid()"
  />
</template>

<script>
import GroupReservations from "@/views/groups/group/GroupReservations/GroupReservations.vue";
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    GroupReservations,
  },
  computed: {
    group() {
      return this.$store.state["app-groups"]?.currentGroup;
    },
  },
  methods: {
    regenerateComponentsUuid() {
      this.componentsKey = uuidv4();
    },
  },
};
</script>
