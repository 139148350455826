<template>
  <b-modal
    id="group-advanced-information-modal"
    title="Demande modification/annulation"
    :ok-title="t('group.request.send')"
    :cancel-title="t('button.cancel')"
    cancel-variant="outline-secondary"
    size="lg"
    @ok="submitFormData"
    @cancel="resetForm"
    @show="fillDataIfProvided"
    no-close-on-backdrop
  >
    <b-form>
      <b-row>
        <b-col md="6">
          <b-form-group label="Numéro de réservation" label-for="link">
            <b>#{{ reservation ? reservation.reservation_number : '' }}</b>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            :label="t('group.request.reservation_request_type_label')"
            label-for="request-type"
          >

            <v-select
              id="request-type"
              ref="request-type"
              v-model="formData.type"
              :options="request_types_options"
              label="text"
              :reduce="text => text.value"           
            />

          </b-form-group>

        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group label="Quel est votre demande?" label-for="description">
            <b-form-textarea
              rows="4"
              size="sm"
              :placeholder="t('group.request.navbar_title')"
              v-model="formData.description"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>

    </b-form>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormTags,
  BFormTextarea,
  BFormFile,
  BImg,
  BOverlay,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import {useLocalisation} from "@/shared/composables/use-localisation";
import {useValidatorsWithAutoFormDataCleanUp} from "@/shared/composables/use-validators";
import {reactive, ref, watch} from "@vue/composition-api/dist/vue-composition-api";
import {useGroupApi} from "@/modules/group/composables/use-group-api";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {useRequestTemplater} from "@/modules/requests/composables/use-request-templater";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";

export default {
  name: 'group-advanced-information-modal',
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BOverlay,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormTags,
    BFormFile,
    BImg
  },
  props: {
    reservation: {
      type: Object,
    },
  },
  directives: {
    Ripple,
  },
  setup(props, {emit}) {
    const {updateGroupAdvancedInformation, uploadImage} = useGroupApi();
    const {forceUpdate, store} = useApplicationContext();
    const {t} = useLocalisation();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();
    const {getAllAvailableGroups} = useGroupApi();

    const REQUEST_TYPES = {
      CHANGE_RESERVATION:"change_reservation",
      CANCEL_RESERVATION:"cancel_reservation"
    }



    const currentAuthUser = ref({});
    currentAuthUser.value = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];

    let emailForRequest = null;
    try{
      if (store.state[AUTH_STORE_NAMESPACE].orgSettings?.request_email) {
        emailForRequest = store.state[AUTH_STORE_NAMESPACE].orgSettings.request_email
      }
    } catch (error) {
      console.error(error)
    }

    const {generateEmailLinkByType} = useRequestTemplater(emailForRequest);


    let formData = reactive({
      link: '',
      type: '',
      authUser: currentAuthUser,
      reservation: null
    });  
    const fillDataIfProvided = () => {
      if (props.action === 'update') {
        Object.assign(formData, props.information);
        forceUpdate();
      }
    };

    const submitFormData = async () => {
      try {
        formData.reservation = props.reservation

        switch (formData.type){
          case REQUEST_TYPES.CHANGE_RESERVATION:
            window.location.href = generateEmailLinkByType(REQUEST_TYPES.CHANGE_RESERVATION, formData)
            break;
          case REQUEST_TYPES.CANCEL_RESERVATION:
            window.location.href = generateEmailLinkByType(REQUEST_TYPES.CANCEL_RESERVATION, formData)
            break;
        }

  

        displaySuccessMessage('Votre email sera généré dans votre gestionnaire de courriel par défault');
      } catch(error) {
        console.error(error)
        displayErrorMessage("La sauvegarde d'information du groupe a échoué");
      }
    };

    const request_types_options = [
      {text: "Demande d’annulation d’une reservation", value: REQUEST_TYPES.CANCEL_RESERVATION},
      {text: "Demande de modification d’une reservation", value: REQUEST_TYPES.CHANGE_RESERVATION}
    ];


    const {resetForm} = useValidatorsWithAutoFormDataCleanUp(formData);

    return {
      formData,
      t,
      resetForm,
      submitFormData,
      fillDataIfProvided,
      request_types_options,
      REQUEST_TYPES,
    };
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
